import { FC, useEffect, useMemo, useState } from 'react';
import AppLogo from '../../../assets/images/AppLogo';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import classnames from 'classnames';
import CheckMark from '../../../assets/icons/CheckMark';
import { isValidEmail } from '../../../utils/validators';
import { usePortal } from '../../../utils/usePortal';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBack from '../../../assets/images/ArrowBack';
import Confirmation from '../../../components/Modal/Confirm';
import countries from '../../../data/countries.json';
import Select, { components } from 'react-select';
import TrackerSdk from '../../../utils/TrackingSdk';
import useListener from '../../../utils/useListener';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../../i18n/namespaces';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { ConfigProvider } from 'antd';
import updateLocale from 'dayjs/plugin/updateLocale';

import localeEN from 'antd/es/locale/en_US';
import localeES from 'antd/es/locale/es_ES';
import localePT from 'antd/es/locale/pt_BR';

dayjs.extend(updateLocale);

const ProfileForm = ({ profile, onUpdate, title, isUpdate }: any) => {
  const { id, apiKey, jwt, type } = useParams();
  const [formData, setFormData] = useState(profile);
  const [isValidForm, setIsValidForm] = useState(isUpdate);
  const navigate = useNavigate();
  const portal = usePortal();
  const [isDraft, setIsDraft] = useState(false);
  const { handleSubmit, control } = useForm();
  const { t, i18n } = useTranslation(Namespace.Appointment);
  const selectedLocale =
    i18n.language === 'es'
      ? localeES
      : i18n.language === 'pt'
        ? localePT
        : localeEN;

  const gender = [
    {
      value: 'male',
      label: t('profile_details.male'),
      defaultChecked: profile.gender == 'male',
    },
    {
      value: 'female',
      label: t('profile_details.female'),
      defaultChecked: profile.gender == 'female',
    },
  ];

  useEffect(() => {
    dayjs.updateLocale(i18n.language, {
      weekStart: 1,
    });
  }, [i18n.language, selectedLocale]);

  useEffect(() => {
    if (isUpdate) {
      TrackerSdk.pageView('edit your data pageview');
    } else {
      TrackerSdk.pageView('complete your profile pageview');
    }
  }, []);

  function toProfileDetail() {
    navigate(`/${type}/${id}/${apiKey}/${jwt}`);
  }

  useListener({
    listener: 'message',
    dependencies: [],
    onEvent: ({ data: { command } }: MessageEvent<any>) => {
      switch (command) {
        case 'videocall_profile_verify':
          onProfileDraft();
          break;
        default:
          break;
      }
    },
  });

  function onProfileDraft() {
    if (isDraft) {
      TrackerSdk.pageView('edit your data exit pageview');
      portal.open(
        <Confirmation
          title={t('profile_form.draft_confirmation.title')}
          onConfirmText={t('profile_form.draft_confirmation.confirm')}
          onCancelText={t('profile_form.draft_confirmation.cancel')}
          onConfirm={() => {
            isValidForm ? onSubmit() : toProfileDetail();
            TrackerSdk.event(
              'edit your data exit save and continue button click',
            );
            portal.close();
          }}
          onCancel={() => {
            toProfileDetail();
            TrackerSdk.event('edit your data exit without save button click');
            portal.close();
          }}
          onClose={portal.close}
          backdropTransparent={false}
        />,
      );
    } else {
      toProfileDetail();
    }
  }

  const selectDate = (value: any) => {
    let newData = {
      ...formData,
      birth_date: value ? dayjs(value).format('YYYY-MM-DD') : '',
    };
    setIsDraft(true);
    setIsValidForm(isFormValid(newData));
    setFormData(newData);
  };

  return (
    <div className="bg-background">
      <div className="relative max-h-screen h-screen px-6 grid max-w-[800px] mx-auto">
        <div className="w-full">
          <div className="border-b-2 my-6">
            {type !== 'i' && (
              <div className="flex pb-6 mb-3 items-center">
                {isUpdate && (
                  <div
                    className="sm:mr-8 text-gray-dark"
                    onClick={() => onProfileDraft()}
                  >
                    <ArrowBack className="h-4" />
                  </div>
                )}
                <AppLogo />
              </div>
            )}
            <h1 className="text-primary text-2xl mb-2 text-center sm:text-start">
              {title}
            </h1>
            <p className="text-sm text-text text-center mb-6 text-gray-dark font-lighter sm:text-start">
              {t('profile_form.subtitle')}
            </p>
          </div>
          <form
            className="w-full flex flex-col my-8"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h3 className="text-gray-dark text-xs font-medium mb-2 uppercase">
              {t('profile_form.personal_data')}
            </h3>
            <p className="text-xs mb-3 text-gray-dark">
              {t('profile_form.personal_data__warn')}
            </p>
            <Form.Field
              type="text"
              defaultValue={formData?.first_name}
              name="first_name"
              label={t('profile_form.name')}
              required
              placeholder={t('profile_form.name')}
              errors={!formData.first_name}
              onChange={textFieldListener}
              readOnly={profile?.first_name}
            />
            <Form.Field
              type="text"
              defaultValue={formData?.last_name}
              name="last_name"
              label={t('profile_form.last_name')}
              placeholder={t('profile_form.last_name')}
              required
              errors={!formData.last_name}
              onChange={textFieldListener}
              readOnly={profile?.last_name}
            />
            <Form.Field
              type="text"
              defaultValue={formData?.tax_id}
              name="tax_id"
              label={t('profile_details.dni')}
              placeholder={t('profile_details.dni')}
              required
              errors={!formData.tax_id}
              onChange={textFieldListener}
              readOnly={profile?.tax_id}
            />
            <div className="flex bg-bg relative flex-col rounded-lg pl-2 mb-2">
              <span className=" absolute font-light text-sm text-primary whitespace-norwrap ml-2.5 mt-2.5 tracking-wide">
                {t('profile_details.birth_date')}
              </span>
              <ConfigProvider locale={selectedLocale}>
                <DatePicker
                  name="birth_date"
                  placeholder={
                    profile?.birth_date
                      ? dayjs(profile?.birth_date).format('DD/MM/YYYY')
                      : 'dd/mm/yyyy'
                  }
                  variant="borderless"
                  format={'DD/MM/YYYY'}
                  size="large"
                  style={{ width: '95%' }}
                  onChange={selectDate}
                  disabled={profile?.birth_date}
                />
              </ConfigProvider>
            </div>
            <h3 className="text-gray-dark text-xs font-medium mb-2 uppercase">
              {t('profile_form.gender')}
            </h3>
            <div className="flex mb-2 space-x-4">
              {gender.map(({ label, value }) => (
                <RadioButton
                  key={value}
                  name="gender"
                  label={label}
                  value={value}
                  checked={formData.gender === value}
                  onClick={checkboxListener}
                  readOnly={profile?.gender}
                  disabled={profile?.gender}
                />
              ))}
            </div>
            <h3 className="text-gray-dark text-xs font-medium mb-2 uppercase">
              {t('profile_form.contact')}
            </h3>
            <Form.Field
              type="email"
              defaultValue={formData?.email}
              name="email"
              label={t('profile_details.email')}
              placeholder={t('profile_details.email')}
              errors={!isValidEmail(formData?.email ?? '')}
              onChange={textFieldListener}
            />
            <div className="flex">
              <Dropdown
                defaultValue={
                  formData?.phone_prefix ? '+' + formData?.phone_prefix : '+34'
                }
                name={'phone_prefix'}
                control={control}
                options={countries.map(({ prefix, name }: any) => ({
                  value: prefix,
                  label: `${name} (${prefix})`,
                }))}
                onChange={prefixListener}
              />
              <Form.Field
                type="tel"
                defaultValue={formData?.phone}
                label={t('profile_details.phone')}
                placeholder={t('profile_details.phone')}
                name="phone"
                errors={!formData.phone}
                onChange={textFieldListener}
              />
            </div>
            <Button type="submit" disabled={!isValidForm} className="mt-3">
              {t('profile_form.confirm')}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );

  function checkboxListener(event: any) {
    let newData = {
      ...formData,
      gender: event.target.value,
    };
    setIsDraft(true);
    setIsValidForm(isFormValid(newData));
    setFormData(newData);
  }

  function prefixListener(prefix: any) {
    let newData = {
      ...formData,
      phone_prefix: prefix,
    };
    setIsDraft(true);
    setIsValidForm(isFormValid(newData));
    setFormData(newData);
  }

  function textFieldListener(event: any) {
    let newData = {
      ...formData,
    };

    switch (event.target.name) {
      case 'first_name':
        newData.first_name = event.target.value;
        break;
      case 'last_name':
        newData.last_name = event.target.value;
        break;
      case 'tax_id':
        newData.tax_id = event.target.value;
        break;
      case 'email':
        newData.email = event.target.value;
        break;
      case 'phone':
        newData.phone = event.target.value;
        break;
      default:
        break;
    }
    setIsDraft(true);
    setIsValidForm(isFormValid(newData));
    setFormData(newData);
  }

  function onSubmit() {
    if (isUpdate) {
      TrackerSdk.event('edit your data confirm button click');
    } else {
      TrackerSdk.event('complete your profile confirm button click');
    }

    onUpdate({
      formData: {
        ...formData,
        phone_prefix: formData['phone_prefix']
          ? formData['phone_prefix']
          : '+34',
      },
      closure: () => {
        if (isUpdate) {
          toProfileDetail();
        }
      },
    });
  }

  function isFormValid(profile: any): boolean {
    return !(
      !profile.first_name ||
      !profile.last_name ||
      !isValidEmail(profile.email ?? '') ||
      !profile.birth_date ||
      !profile.gender ||
      !profile.phone ||
      !profile.tax_id
    );
  }
};

// Components
const RadioButton = ({
  name,
  checked,
  label,
  value,
  register,
  disabled,
  defaultChecked,
  onChange,
  onClick,
  readOnly,
}: any) => (
  <label
    className={classnames(
      'flex justify-between font-lighter relative group cursor-pointer w-full px-4 py-4 text-center border rounded-lg',
      {
        'hover:border-primary': !readOnly,
        'border-primary bg-blue-light profile-form-checked': checked,
        'border-separators': !checked,
      },
    )}
  >
    <input
      className="hidden"
      name={name}
      type="radio"
      disabled={disabled}
      value={value}
      ref={register}
      defaultChecked={defaultChecked}
      onClick={onClick}
      readOnly={readOnly}
    />

    <div
      className={classnames({
        'text-dark': !checked,
        'text-primary': checked,
      })}
    >
      {label}
    </div>
    {checked ? (
      <CheckMark
        className={classnames('mr-0 group-hover:text-primary', {
          'text-gray-dark': !checked,
          'text-primary': checked,
        })}
      />
    ) : (
      <div></div>
    )}
    <div
      className={classnames('absolute inset-0 w-full h-full opacity-10', {
        'bg-blue-light': checked,
      })}
    />
  </label>
);

const Dropdown: FC<any> = ({
  name,
  options,
  defaultValue,
  control,
  onChange,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange: onChangeRender, value, name } }) => (
      <Select
        className={classnames('Dropdown prefix w-28')}
        styles={{
          menu: (provided: any) => ({ ...provided, width: '250px' }),
          singleValue: (base: any) => ({ ...base }),
        }}
        components={{ SingleValue }}
        isSearchable={true}
        classNamePrefix="ReactDropdown"
        options={options}
        value={options.find((o: any) => o.value === value)}
        name={name}
        defaultValue={options.find((o: any) => o.value === defaultValue)}
        onChange={(selectedOption: any) => {
          onChange(selectedOption.value);
          onChangeRender(selectedOption.value);
        }}
      />
    )}
  />
);

const SingleValue: FC<any> = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {children.match(/\((.*)\)/)[1]}
  </components.SingleValue>
);

export default ProfileForm;
